import './app/bootstrap'
import './app/index.css'
import '@imwebme/clay-stylesheet'

import { HelmetProvider } from 'react-helmet-async'
import ReactDOM from 'react-dom/client'
import { sentryCreateBrowserRouter } from './app/provider/sentry'
import { RouterProvider } from 'react-router-dom'

ImwebOAuth2ClientAPI.init().then(() => {
  import('@imwebme/generouted-react-router').then(({ routes }) => {
    // 앱이 정상 시작했다.
    window.dispatchEvent(new CustomEvent('app-ready'))
    const router = sentryCreateBrowserRouter(routes)
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    )
  })
})
